import { NavLink } from "react-router-dom";
import logo from "../assets/images/logo-h.png";
import avatar from "../assets/images/avtar.png";
import { useContext } from "react";
import AuthContext from "../context/authContext";
const Header = () => {
  const {user, logout} = useContext(AuthContext);


  return (
    <>
      <nav className="navbar main-nav  navbar-expand-lg  fixed-top">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img src={logo} className="img-fluid main-logo" alt="logo" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.72093 0C2.56744 0 0 2.56744 0 5.72093C0 8.87442 2.56744 11.4419 5.72093 11.4419H11.4419V5.72093C11.4419 2.56744 8.87442 0 5.72093 0ZM9.2093 9.2093H5.72093C3.79535 9.2093 2.23256 7.64651 2.23256 5.72093C2.23256 3.79535 3.79535 2.23256 5.72093 2.23256C7.64651 2.23256 9.2093 3.79535 9.2093 5.72093V9.2093Z"
                fill="#e4c499"
              ></path>
              <path
                d="M18.2834 0C15.1299 0 12.5625 2.56744 12.5625 5.72093V11.4419H18.2834C21.4369 11.4419 24.0044 8.87442 24.0044 5.72093C24.0044 2.56744 21.4369 0 18.2834 0ZM18.2834 9.2093H14.7951V5.72093C14.7951 3.79535 16.3578 2.23256 18.2834 2.23256C20.209 2.23256 21.7718 3.79535 21.7718 5.72093C21.7718 7.64651 20.209 9.2093 18.2834 9.2093Z"
                fill="#e4c499"
              ></path>
              <path
                d="M0 18.279C0 21.4325 2.56744 24 5.72093 24C8.87442 24 11.4419 21.4325 11.4419 18.279V12.5581H5.72093C2.56744 12.5581 0 15.1255 0 18.279ZM9.2093 18.279C9.2093 20.2046 7.64651 21.7674 5.72093 21.7674C3.79535 21.7674 2.23256 20.2046 2.23256 18.279C2.23256 16.3535 3.79535 14.7907 5.72093 14.7907H9.2093V18.279Z"
                fill="#e4c499"
              ></path>
              <path
                d="M18.2834 12.5581H12.5625V18.279C12.5625 21.4325 15.1299 24 18.2834 24C21.4369 24 24.0044 21.4325 24.0044 18.279C24.0044 15.1255 21.4369 12.5581 18.2834 12.5581ZM18.2834 21.7674C16.3578 21.7674 14.7951 20.2046 14.7951 18.279V14.7907H18.2834C20.209 14.7907 21.7718 16.3535 21.7718 18.279C21.7718 20.2046 20.209 21.7674 18.2834 21.7674Z"
                fill="#e4c499"
              ></path>
            </svg>{" "}
          </button>

          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mr-auto align-items-center">
              <li className="nav-item active">
                <NavLink className="nav-link" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about-us">
                  ABout us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/contact-us">
                  Contact us
                </NavLink>
              </li>

            <div>
              {!user?.first_name ? (
                <NavLink to="/login">
                  <button className="primary-btn header-btn">Login</button>
                </NavLink>
              ) : (
                ""
              )}
              </div>

              {user?.first_name && (
                <>
                  <NavLink to="/book-appointment">
                    <button className="primary-btn header-btn">
                      Book An Appointment
                    </button>
                  </NavLink>
                  <div className="profile-drp d-flex align-items-center ml-5">
                    <span className="p-name">{user?.first_name}</span>
                    <li className="nav-item dropdown ">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src={avatar}
                          width="40"
                          height="40"
                          className="rounded-circle"
                        />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdownMenuLink"
                      >
                        <NavLink
                          to="/change-password"
                          role="button"
                          className="dropdown-item"
                        >
                          Change Password
                        </NavLink>
                        <NavLink
                          to="/appointment-list"
                          role="button"
                          className="dropdown-item"
                        >
                          My Appointment
                        </NavLink>
                        <span
                          className="dropdown-item"
                          role="button"
                          onClick={logout}
                        >
                          Log Out
                        </span>
                      </div>
                    </li>
                  </div>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
