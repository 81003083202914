import { useEffect } from "react";

const Contact = () => {
  document.title = "Contact Us | HEMMY'S BRIDAL STUDIO";

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="get-in-touch top-sec">
        <div className="container">
          <div className="main-content">
            <div className="card">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-12">
                  <div className="first-content">
                    <h1 className="main-h">Get in Touch</h1>
                    <div className="info">
                      <h6 className="para-txt">
                        Wembley, London, UK
                        <br /> 532 High Road, HA9 7BS
                      </h6>

                      <h6 className="para-txt">
                        020 8900 1612
                        <br />
                        Mon to Sat 10 AM to 7 PM
                      </h6>
                      <h6 className="para-txt">
                        indulgencenailbeauty@gmail.com 
                      </h6>
                    </div>
                    <div className="social-sec">
                      <h6 className="p-txt">Follow Us!</h6>

                      <div className="social-icons">
                        <ul className="icon-ul">
                          <li>
                            <a href="#" className="f-link">
                              <i className="fa fa-facebook" aria-hidden="true"></i>
                            </a>
                          </li>

                          <li className="ml-2">
                            <a href="#" className="f-link">
                              <i className="fa fa-instagram" aria-hidden="true"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-12 d-flex justify-content-end mobile-div">
                <div className="content-card">
                          <div className="form-group">
                            <input
                              type="email"
                              id="emall"
                              className="form-control"
                              placeholder="Email"
                              autocomplete="off"
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              id="name"
                              className="form-control"
                              required
                              autocomplete="off"
                              placeholder="Name"
                            />
                          </div>
                          <div className="form-group">
                            <textarea
                              type="text"
                              id="coment"
                              className="form-control"
                              required
                              autocomplete="off"
                              placeholder="Comment"
                            />
                          </div>
                          <div className="send-btn">
                            <button className="contact-btn">
                              <div className="svg-wrapper-1">
                                <div className="svg-wrapper">
                                  <svg
                                    height="24"
                                    width="24"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                    <path
                                      d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                              <span>Send</span>
                            </button>
                          </div>
                        </div>

{/*                         
                  <div className="second-content">
                    <div className="row justify-content-end  row-tab">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-12">
                        
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
