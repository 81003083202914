import { Fragment, useState } from "react";
import service1 from "../../assets/images/gallary3.jpg";
import service2 from "../../assets/images/service2.jpg";
import service3 from "../../assets/images/service3.jpg";
import { useEffect } from "react";
import { GET_CATEGORY } from "../../config/apiUrl";
import axiosInstace from "../../config/axiosInstance";
import Loader from "../../components/Loader";

const Service = () => {
  const [category, setCategoty] = useState([]);
  const [pageLoad, setPageLoad] = useState(false);

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    try {
      setPageLoad(true);
      const response = await axiosInstace.get(GET_CATEGORY);
      if (response) {
        setCategoty(response?.data?.payload?.results);
        setPageLoad(false);
      }
    } catch (error) {
      setCategoty([]);
      setPageLoad(false);
    }
  };
  return (
    <>
      <div className="main-service ">
        <div className="container">
          <div className="main-content">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12 header-sec">
                <h1 className="main-h text-center">OUR SERVICES</h1>
                <h6 className="para-txt text-center">HERE IS WHAT ALL WE DO</h6>
              </div>
              {category &&
                category.length > 0 &&
                category.map((item, index) => (
                  <Fragment key={index}>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-12 col-sm-12 content">
                      <div className="box-item">
                        <div className="flip-box">
                          <div
                            className="flip-box-front text-center"
                            style={{ backgroundImage: `url(${service1})` }}
                          >
                            <div className="inner color-white">
                              <h3 className="para-txt">Bridal Make-up</h3>
                              <h6 className="p-txt">
                                WE AT BRIDAL STUDIO ENSURE THAT OUR WOULD BE
                                BRIDES SHOULD GET OUR BEST OF THE RESULTS IN
                                EACH CATEGORY OF OUR SERVICE. BE IT PRE - BRIDAL
                                (THE MOST IMPORTANT PART) OF HER BRIDAL JOURNEY
                                TO ACHIEVE THE BEST DESIRED GLOWING RESULTS ON
                                HER D-DAY ON THE STAGE IN BETWEEN THE LIGHTS AND
                                CAMERAS.
                              </h6>
                            </div>
                          </div>
                          <div
                            className="flip-box-back text-center"
                            style={{ backgroundImage: `url(${service1})` }}
                          >
                            <div className="inner color-white">
                              <h3 className="para-txt">Bridal Make-up</h3>
                              <h6 className="p-txt">
                                WE AT BRIDAL STUDIO ENSURE THAT OUR WOULD BE
                                BRIDES SHOULD GET OUR BEST OF THE RESULTS IN
                                EACH CATEGORY OF OUR SERVICE. BE IT PRE - BRIDAL
                                (THE MOST IMPORTANT PART) OF HER BRIDAL JOURNEY
                                TO ACHIEVE THE BEST DESIRED GLOWING RESULTS ON
                                HER D-DAY ON THE STAGE IN BETWEEN THE LIGHTS AND
                                CAMERAS.
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))}

              <div className="col-xl-4 col-lg-4 col-md-6 col-12 col-sm-12 content">
                <div className="box-item">
                  <div className="flip-box">
                    <div
                      className="flip-box-front text-center"
                      style={{ backgroundImage: `url(${service2})` }}
                    >
                      <div className="inner color-white">
                        <h3 className="para-txt">Henna</h3>
                        <h6 className="p-txt">
                          NOW A DAYS WITH THE CHANGING TRENDS,IT HAS ALSO BECOME
                          AS GRAND AS WEDDING CELEBRATION AS ITS BEEN CELEBRATED
                          JOINTLY BY BRIDE AND GROOM'S FAMILIES TOGETHER.WE
                          ENSURE YOU THE GREAT NATURAL ENHANCED PRETTY MAKE-UP
                          LOOK WITH BEST COSMETICS OUT OF MY VANITY FOR YOU.
                        </h6>
                      </div>
                    </div>
                    <div
                      className="flip-box-back text-center"
                      style={{ backgroundImage: `url(${service2})` }}
                    >
                      <div className="inner color-white">
                        <h3 className="para-txt">Henna</h3>
                        <h6 className="p-txt">
                          NOW A DAYS WITH THE CHANGING TRENDS,IT HAS ALSO BECOME
                          AS GRAND AS WEDDING CELEBRATION AS ITS BEEN CELEBRATED
                          JOINTLY BY BRIDE AND GROOM'S FAMILIES TOGETHER.WE
                          ENSURE YOU THE GREAT NATURAL ENHANCED PRETTY MAKE-UP
                          LOOK WITH BEST COSMETICS OUT OF MY VANITY FOR YOU.
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

             {/*  <div className="col-xl-4 col-lg-4 col-md-6 col-12 col-sm-12 content">
                <div className="box-item">
                  <div className="flip-box">
                    <div
                      className="flip-box-front text-center"
                      style={{ backgroundImage: `url(${service3})` }}
                    >
                      <div className="inner color-white">
                        <h3 className="para-txt">Hair Style</h3>
                        <h6 className="p-txt">
                          WITH CHANGING AND ONGOING TREND OF JOINT WEDDINGS
                          (BRIDE AND GROOM FAMILIES) WE HAVE KEPT IN MIND THE
                          NEED OF MAKEUP AND HAIR STYLING FOR BOTH SIDE FAMILY
                          LADIES ALONG WITH THE BRIDE MAKE-UP.
                        </h6>
                      </div>
                    </div>
                    <div
                      className="flip-box-back text-center"
                      style={{ backgroundImage: `url(${service3})` }}
                    >
                      <div className="inner color-white">
                        <h3 className="para-txt">Hair Style</h3>
                        <h6 className="p-txt">
                          WITH CHANGING AND ONGOING TREND OF JOINT WEDDINGS
                          (BRIDE AND GROOM FAMILIES) WE HAVE KEPT IN MIND THE
                          NEED OF MAKEUP AND HAIR STYLING FOR BOTH SIDE FAMILY
                          LADIES ALONG WITH THE BRIDE MAKE-UP.
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {pageLoad === true && <Loader />}

    </>
  );
};

export default Service;
